<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  //App.vue
created() {
  // 通过方法的值判断走哪个路由
    if (this._isMobile()) {
      // this.$router.replace('/mobilehome');
      window.location.href = 'https://unicloud.zmddg.com/h5#/'
    } else {
      this.$router.replace('/home');
    }
  },
  mounted(){

  },
  methods: {
    // 判断是手机还是电脑
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style>
.record-number {
  display: block;
  color: #333;
}
</style>
