import Vue from 'vue'
import Router from 'vue-router'

const HomePage = () => import( '../components/HomePage')
// 移动端的主页
const MobileHome = () => import( '../components/MobileHome')
const AboutPage = () => import('../components/AboutPage')
const IntroducePage = () => import('../components/IntroducePage')
const MobileAbout = () => import('../components/MobileAbout')
const MobileIntroducePage = () => import('../components/MobileIntroducePage')

Vue.use(Router)


const router = new  Router({
  routes: [
    { 
      path: '/', redirect: '/home' 
    },
    { 
      path: '/home',
      component: HomePage 
    },
    {
      path: '/about',
      component: AboutPage
    },
    {
      path: '/introduce',
      component: IntroducePage
    },{
      path: '/mobilehome',
      component: MobileHome 
    },{
      path: '/mobileabout',
      component: MobileAbout 
    },
    {
      path: '/mobileintroducepage',
      component: MobileIntroducePage 
    }
  ]
})


const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router

